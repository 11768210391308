.copy{
    font-size: 0.85rem;

    &:hover{
        background-image: linear-gradient(to right, var(--tw-gradient-stops));
        --tw-gradient-from: #5cffe4;
        --tw-gradient-to: rgb(66 166 227 / 0);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
        --tw-gradient-to: #FF56F6;
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 700; 
        color: transparent;
    }
}


