
#about{
    padding-top: 13rem;
    padding-bottom: 7rem;

    h2{font-family: zuumeblack!important;}

    .wrapper {
        margin-left: auto;
        margin-right: auto;
        max-width: 1640px;
        padding-left: 100px;
        padding-right: 100px;
        width: 100%;
    }

.about-me-section {
    text-align: center;
}
.about-me-section .content {
    display: inline-block;
    position: relative;
    text-align: center;
}
.about-me-section .content .heading {
    font-size: 72px;
    font-weight: 900;
    letter-spacing: 0.02em;
    line-height: 80%;
    position: relative;
    z-index: 2;
}
@media screen and (min-width: 768px) {
    .about-me-section .content .heading {
        font-size: calc(-28.57143px + 13.09524vw);
    }
}
@media screen and (min-width: 1440px) {
    .about-me-section .content .heading {
        font-size: 160px;
    }
}
.about-me-section .content .paragraph {
  
    font-size: 16px;
    line-height: 1.6;
    margin: 40px auto 0;
    max-width: 100%;
    position: relative;
    z-index: 2;
}
@media screen and (min-width: 768px) {
    .about-me-section .content .paragraph {
        font-size: calc(6.85714px + 1.19048vw);
    }
}
@media screen and (min-width: 1440px) {
    .about-me-section .content .paragraph {
        font-size: 24px;
    }
}
@media (min-width: 500px) {
    .about-me-section .content .paragraph {
        width: clamp(440px, 42.0315236427vw, 720px);
    }
}
.about-me-section .content .btn {
    margin-top: 50px;
    position: relative;
    z-index: 2;
}
.about-me-section .tags {
    display: none;
    pointer-events: none;
}
@media (min-width: 768px) {
    .about-me-section .tags {
        display: block;
    }
}
.about-me-section .tag {
    color: #0f0f0f;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    padding: clamp(12px, 1.4010507881vw, 24px) clamp(16px, 1.8680677175vw, 32px);
    position: absolute;
    text-transform: uppercase;
    white-space: nowrap;
}
@media screen and (min-width: 768px) {
    .about-me-section .tag {
        font-size: calc(-2.28571px + 2.38095vw);
    }
}
@media screen and (min-width: 1440px) {
    .about-me-section .tag {
        font-size: 32px;
    }
}
.about-me-section .avatars {
    display: none;
    pointer-events: none;
}
@media (min-width: 768px) {
    .about-me-section .avatars {
        display: block;
    }
}
.about-me-section .avatar {
    border-radius: 50%;
    height: clamp(40px, 9.3403385873vw, 160px);
    overflow: hidden;
    position: absolute;
    width: clamp(40px, 9.3403385873vw, 160px);
}
.about-me-section .avatar img {
    display: block;
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}
.about-me-section .yellow {
    // background: #ffe95c;
    left: calc(50% - 36.19381vw);
    top: 9.3403385873vw;
}
@media (min-width: 1280px) {
    .about-me-section .yellow {
        left: calc(50% - 30.3561vw);
        top: 9.3403385873vw;
    }
}
.about-me-section .texas {
    // background: #ffaf5c;
    left: calc(50% - 4.67017vw);
    top: -8.4063047285vw;
}
@media (min-width: 1280px) {
    .about-me-section .texas {
        left: calc(50% - 4.67017vw);
        top: -8.4063047285vw;
    }
}
.about-me-section .violet {
    // background: #cb5cff;
    left: calc(50% + 26.85347vw);
    top: -0.9340338587vw;
}
@media (min-width: 768px) {
    .wrapper{padding-left: 40px;
        padding-right: 40px;}
}

.about-me-section .watermelon {
    background: #ff5c7d;
    bottom: -0.9340338587vw;
    left: calc(50% + 16.34559vw);
}
@media (min-width: 1280px) {
    .about-me-section .watermelon {
        bottom: -0.9340338587vw;
        left: calc(50% + 11.67542vw);
    }
}

@media (min-width: 500px){
.about-me-section .content .paragraph {
    width: clamp(440px,42.0315236427vw,720px);
}

}


.btn {
    margin-top: 50px;
    --btnH: 56px;
    --paddingX: 24px;
    --paddingY: 16px;
    align-items: center;
    background: transparent;
    border: 0;
    border-radius: 1em;
    box-sizing: border-box;
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    gap: 16px;
    height: var(--btnH);
    justify-content: center;
    line-height: calc(var(--btnH) - var(--paddingY) - 2px);
    padding: var(--paddingY) var(--paddingX);
    position: relative;
    transition-delay: 0s;
    transition-duration: 0.2s;
    transition-property: background-color, color, border-color;
    transition-timing-function: cubic-bezier(0.38, 0.005, 0.215, 1);
}

@media (min-width: 768px) {
    .btn {
        --btnH: 64px;
        --paddingX: 32px;
        border-radius: 1em;
        font-size: 20px;
    }
}

.btn {
	&:active {
		&:focus {
			transition-duration: 0.4s;
		}
	}
	&:hover {
		transition-duration: 0.4s;

        .btn-bg {
			&:before {
				opacity: 1;
                transform: scale(1);
                transition: transform 0.8s cubic-bezier(0.38, 0.005, 0.215, 1), opacity 0s cubic-bezier(0.38, 0.005, 0.215, 1);
			}
		}

	}
}

.btn-bg {
    border-radius: inherit;
    bottom: -1px;
    left: -1px;
    overflow: hidden;
    position: absolute;
    right: -1px;
    top: -1px;
    transform: translateZ(0);

    &:before {
        border-radius: 50%;
        content: "";
        height: calc(var(--btnH) * 8);
        left: 50%;
        margin-left: calc(var(--btnH) * 8 / -2);
        margin-top: calc(var(--btnH) * 8 / -2);
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: scale(0);
        transform-origin: center center;
        transition: transform 0.8s cubic-bezier(0.38, 0.005, 0.215, 1), opacity 0.2s cubic-bezier(0.38, 0.005, 0.215, 1);
        width: calc(var(--btnH) * 8);
    }
}

.btn-text {
    align-items: center;
    display: flex;
    gap: inherit;
    justify-content: center;
    position: relative;
    z-index: 3;
}

.btn-sm {
    --btnH: 56px;
    --paddingX: 24px;
    font-size: 16px;
    gap: 12px;
}

.btn-sm svg {
    height: auto;
    width: 15px;
}

@media (min-width: 768px) {
    .btn-sm {
        --btnH: 56px;
    }
}

.btn-lg {
    --btnH: 56px;
    --paddingX: 24px;
    font-size: 16px;
}

@media (min-width: 1280px) {
    .btn-lg {
        --btnH: 80px;
        --paddingX: 40px;
        font-size: 24px;
    }
}

.btn svg path {
    fill: currentColor;
}

.btn-white{
    .btn-bg {
		&:before {
            background-color: var(--green);
        }
    }
    background-color: #efece6;
    color: #0f0f0f;
    &:focus {
        background-color: #efece6;
        color: #0f0f0f; 
        .btn-bg {
			&:before {
				background-color: var(--green);
			}
		}
    }
    &:active {
        &:focus {
            background-color: #efece6;
            color: #000;
        }
    }
    &:hover {
        background-color: #efece6;
        color: #000;
    }
}

}












