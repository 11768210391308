#portfolio{
  background: #131313;
  .bg-slate{
  background: #1C1D24 !important;
  }

  
  button{
    outline: none;
    border: none;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 1px solid var(--main);
    background: transparent;
    color: var(--main);
    border-radius: 4px;
    position: relative;
    z-index: 20;
    overflow: hidden;
    transition: color .2s;

    &:hover{
      color: #000;
      &:before{

          left: 0;
          top: 0;

      }
    }

    &:before{
      content: "";
      position: absolute;
      z-index: -1;
      background: var(--main);
      height: 100%;
      width: 100%;
      left: -100%;
      top: -100%;
      transition: left .2s,top .2s;
    }

  }

  .pulse {
    animation: pulse 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .loadable {
    border-radius: 1.25rem;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  img {
    height: 100% !important;
  }
  
  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
  }
  
  h3 {
    font-family: 'zuumeblack';
    font-size: 75px;
    font-weight: 900;
    letter-spacing: .02em;
    line-height: 1;
    white-space: nowrap;
  }

  .stroke{
    -webkit-text-stroke: var(--borderWidth) #efece6;
    color: transparent !important;
    text-shadow: none;
    --borderWidth: clamp(1px,0.1167542323vw,2px);
    color: currentColor;
    display: inline-block;
    text-shadow: calc(var(--borderWidth)*-1) calc(var(--borderWidth)*-1) 0 currentColor,var(--borderWidth) calc(var(--borderWidth)*-1) 0 currentColor,calc(var(--borderWidth)*-1) var(--borderWidth) 0 currentColor,var(--borderWidth) var(--borderWidth) 0 currentColor;
    text-shadow: none;
  }

  .one{
    position: relative;
    width: fit-content;
    overflow: hidden;
  }
  
  .two{
    opacity: 1;
    transform: none;
  }

  .portfolio-title{
    font-weight: 900;
    text-align: end;
    font-size: 3rem;
    font-family: 'Open Sans', sans-serif;
    line-height: 5.75rem;
    letter-spacing: 0.1rem;
  }

}

.folio-title{
    font-weight: 900;
    font-size: 3.5rem;
    line-height: 1.2;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    width: fit-content;
    overflow: hidden;
}

.portfolio-list {
    li{
        display: inline-block;
        margin-right: 15px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: .9rem;
    }
}

.folio-heading{
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 4.7px;
  text-transform: uppercase;
}
  
  .p-item{
    width: 479px;
    height: 262px;
    float: right;
  
  }
  
  .portoflio-link{
    font-size: 26px;
  }
  
  .folio-text{
    font-size: 1rem;
    line-height: 1.56;
  }
  
  .recent{
    font-size: 3.3rem;

  }
  
  .item{
    height: 262px;
    max-width: 80%;
    }

    .p-t{
      line-height: 262px;
    }


    .sectionHeader {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    flex-direction: row-reverse; 
    

  }
  
  .title {

    font-size: 3.5rem;
    font-weight: 900;
    text-align: end;
    color: var(--white);
  
    span {
      color: var(--main);
      font-family:'dinregular';
    }
  
    @media (max-width: 768px) {
      font-size: var(--text-lg);
    }
  }
  
  .line {
    width: 72%;
    height: 1px;
    background: var(--white);
    opacity: .3;
  }


  .projects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.2rem;
    column-gap: 3.2rem;

  
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .portfolio-list {
      li{
          display: inline-block;
          margin-right: 15px;
          text-transform: uppercase;
          font-weight: 500;
          font-size: .9rem;
      }
  }
  }

 
  
  .projectImage {
    width: 100%;
    aspect-ratio: 16 / 9;
    background: #232323;
    cursor: pointer;
    position: relative;
    border-radius: 0.8rem;
    overflow: hidden;
  
    /* img {
      width: 85%;
      height: 90%;
      position: absolute;
      bottom: 0;
      left: 50%;
      translate: -50% 20%;
  
      transition: 0.25s all;
  
      border-radius: 0.4rem;
    } */
  
    @media (max-width: 768px) {
      /* img {
        width: 85%;
        height: 90%;
      } */
    }
  }
  
  .projectCopy {
    margin: 1.6rem 0;
  }
  
  .projectTitle {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  
    h4 {
      font-weight: bold;
      font-size:  2.2rem;
      flex-shrink: 0;
  
      max-width: calc(100% - 150px);
    }
  
    a {
      opacity: 0.75;
      transition: 0.25s opacity;
    }
  
    a:hover {
      opacity: 1;
    }
  }
  
  .projectTitleLine {
    width: 100%;
    height: 1px;
    background: #ebecf3;
    opacity: 0.3;
  }
  
  .projectDescription {
    font-weight: 200;
  
    span {
      display: inline-block;
  
      font-size: 1.6rem;
      font-weight: 400;
      color: var(--main);
  
      cursor: pointer;
      margin-top: 2.5rem;
    }
  
    span:hover {
      text-decoration: underline;
    }
  }
  
  .projectTech {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
  
    font-size: 1.6rem;
    color: var(--main);
  
    margin: 0.8rem 0;
  }

  :root{
    --borderWidth: clamp(1px,0.1167542323vw,2px);
  }