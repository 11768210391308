#companies {
  background-repeat: no-repeat;
  right: 0;
  z-index: 1;
  pointer-events: none;
  width: 100%;
  height: 60%;
  background-position: center;
  padding-top:3rem;padding-bottom:3rem;
  overflow: hidden;

  img {cursor: pointer;&:hover {opacity: 1;cursor: pointer;}}
  &:after{content:''}
  @keyframes move-left {
  0%{transform:translateX(0)}
  100%{transform:translateX(-50%)}
  }
  .animate-move-left{animation:move-left 15s linear infinite}
  .hover\:animation-play-state\:paused:hover{animation-play-state:paused}
}

@media screen and (min-width: 1024px){
  .company-container .company-container>div{display:flex;align-items:center;justify-content:space-between}
}

.company-container {
  padding-bottom: var(--spacing-lg);
  padding-top: var(--spacing-lg);
  .company-info {opacity: 0.5;display: flex;align-items: center;justify-content: center;margin-bottom: var(--spacing-sm);
    &:after,&:before {content: '';flex: 1 1;border-bottom: 1px solid var(--white);margin: 1rem;max-width: 60px;}
  }

  > div {display: flex;align-items: center;justify-content: space-between;
    .company-wrapper {display: inline-block;line-height: 0;margin: 10px;}
  }
}

.drac-box {box-sizing: border-box;img { max-width: 100%;display: block;margin: 0;border: none;opacity: 0.6;transition: opacity 0.2s ease-in-out; &:hover {opacity: 1;}}}

.companies {display: block;width: 100%;max-width: 1440px;padding-left: 1.5rem;padding-right: 1.5rem;margin: 0 auto;
  @media screen and (min-width: 768px) { padding-left: 2rem;padding-right: 2rem;}
}

.logo-container{display:inline-block;max-width:100%;overflow:hidden;position:relative;box-sizing:border-box;margin:0}
.logo-wrapper{box-sizing:border-box;display:inline-block;max-width:100%;overflow:hidden;position:relative;margin:0}
#box{display:flex;align-items:center;justify-content:space-between}