#contact{
  .contact-eye{display:none;}
  .btn-contact{display:flex;justify-content:center;align-items:center;width:320px;height:60px;font-style:normal;font-weight:500;font-size:17px;background:none;}
  .email{font-style:normal;font-weight:700;font-size:4rem;line-height:4.5rem;letter-spacing:.375rem}
  .subtitle{font-size:1.1rem;}
  .contact-me{

    text-align:center;text-transform:uppercase;font-size:5rem;line-height:5.75rem;letter-spacing:.29375rem;font-family:"Open Sans",sans-serif;}
   .btn-contact{
      &:hover{
        background:#a9ff03;border:1px solid #a9ff03;color:#000;
      }
   }
  .touch{display: none;}
  


  .section{padding-bottom: 0px !important;}


  &:after{
    content: "";
    display:block;
    width: 156px;
    height: 156px;
    background-image: url('~/public/img/13.png');
    background-repeat: no-repeat;
    filter: invert(.8);
    margin-left: 20%;
    animation: wcSpinner 3s linear infinite;
  }
}


//mask
.main{
  height: 100vh;

  .mask, .body{
    width: 100%;
    height: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blackTernary);
    font-size: 42px;
    line-height: 66px;
    cursor: default;
    
    p{width: 1000px; padding:40px;text-align: center;   }
    span{color: var(--green);}
  }

  .mask{
    mask-image: url("../../img/mask.svg");
    mask-repeat: no-repeat;
    mask-size: 40px;
    background: var(--green);
    position: absolute;
    color: black;
  }

 
}



@keyframes wcSpinner {
	0% {
    transform: rotate(0turn);
  }
  
  100% {
    transform: rotate(1turn);
  }
}
