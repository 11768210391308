
/* variables */
$aqua: #5cffe4; 
$green: #A9FF03; 
$pink: #c58fff;
$white: #FFF;
$black: #000;

:root{
    --background: #000;
    --bg-opaque: HSLA(0,0%,7%,.25);
    --background-light: #232323;
    --background-dark: #080808;
    --white: #FFF;
    --line: #FFF;
    --black: #000;
    --main: #5cffe4;
    --green: #A9FF03; 
    --pink: #c58fff;
    --text-xs: 1.6rem;
    --text-sm: 1.8rem;
    --text-md: 2.2rem;
    --text-lg: 3.6rem;
    --text-xl: 5.6rem;
    --text-2xl: 9.6rem;
  }