@import "./styles/_variables.scss";
@import "./fonts/fonts.scss";
@import "./styles/styles.scss";
@import "./styles/normalize.scss";

@tailwind base;
@tailwind components; 
@tailwind utilities;

@layer components {
    /* Image styles */
    .logo-ticker-image {
      @apply h-8 w-auto;
    }
}

@layer base {
  .section {@apply py-12 lg:py-24;}
  .subtitle {@apply max-w-[520px] mb-16 lg:mb-24;--tw-text-opacity: 1;color: rgb(135 142 153/var(--tw-text-opacity));}
  .btn {@apply py-4 px-7 font-medium text-white flex items-center justify-center rounded-sm;}
  .btn-lg {height: 54px;}
  .btn-md {height: 48px;}
  .btn{padding: 1rem 1.75rem;}
  .input {@apply bg-secondary h-[60px] outline-none pl-6 w-full font-body text-[15px] rounded-sm focus:outline focus:outline-1 focus:outline-accent;}
  .textarea {@apply bg-secondary resize-none w-full outline-none p-6 rounded-sm h-[200px] focus:outline focus:outline-1 focus:outline-accent;}
  .text-accent {--tw-text-opacity: 1;color: rgb(92 255 228/var(--tw-text-opacity));}
  .bg-tertiary{--tw-bg-opacity:1;background-color:rgb(19 20 25/var(--tw-bg-opacity))}
  .bg-primary{--tw-bg-opacity:1;background-color:rgb(5 4 2/var(--tw-bg-opacity))}
}

@layer utilities {
  .title{clip-path:polygon(0 0,200% 0,100% 100%,0% 100%);line-height:clamp(6rem,16vw,10rem)}
  nav a:hover ~ .cursor{transform:scale(5);opacity:0}
  .section-heading{clip-path:polygon(0 0,100% 0%,100% 100%,0 100%)}

  .container {
    @apply lg:max-w-5xl;
  }
}



body{background: #1d1e26;}

/* colors */
.aqua{color: $aqua;}
.green{color: $green;}
.pink{color: $pink;}
.white{color: $white !important;}
.black{color: $black;}

// backgrounds
.bg-aqua{background: $aqua;}
.bg-green{background: $green;} 
.bg-pink{background: $pink;}
.bg-black{background:$black;}

// style overrides
.drac-text-purple{background: $green !important;}

