#services {
  background: #1C1D24 !important;
  .service{background: #191919;}

  .s-icon {
    &:hover {transform: rotateY(360deg);transition: 0.9s ease;}
  }

  .service{
    &:before{background-image:linear-gradient(145deg,#A9FF03 0%,#A9FF03 100%);position:absolute;content:"";left:0;top:0;background-image:-webkit-gradient(linear,left top,right top,from(#A9FF03),to(#A9FF03));background-image:-webkit-linear-gradient(left,#A9FF03,#A9FF03);background-image:linear-gradient(90deg,#A9FF03,#A9FF03);width:100%;height:100%;z-index:-1;opacity:0;visibility:hidden;border-radius:10px;-webkit-transition:all .3s cubic-bezier(.645,.045,.355,1);transition:all .3s cubic-bezier(.645,.045,.355,1);}
    &:hover{box-shadow: 0 10px 25px 10px rgba(0,0,0,.1);-webkit-transform: translateY(-5px);transform: translateY(-5px);border: solid 2px #A9FF03;background: #A9FF03;cursor: pointer;
    &:before{opacity: 1;visibility: visible;}

    .s-icon {color: #000;transform: rotateY(360deg);transition: 0.9s ease;}
    .service-heading{color: #000;}
    .service-text{color: #000 !important;}

    }

  }

}

.s-title{margin-bottom: 2.3rem;font-size: 5rem;line-height: 5.75rem;letter-spacing: .29375rem;font-family: "Open Sans", sans-serif;} 
.service-heading{font-weight: 600;font-size:1vw;letter-spacing: 4.7px;text-transform: uppercase;}
.service-text{font-style: normal;line-height: 30px;font-size: .875rem;font-weight: 400;letter-spacing: .025rem;}

  @media (max-width: 990px){
    .service-heading{font-size: 1.4rem;}
  }

  @media (max-width: 600px){
    .service-heading{font-size: 1.3rem;}
    #services{.s-title{font-size:3rem;}}
  }

