#skills-2{
  .skills{display:flex;place-items:center;margin:0;padding:0;list-style-type:none;text-indent:none;gap:48px;position:relative;flex-direction:row;will-change:transform;transform:translateX(0px)}

  .skills-wrapper{display:flex;place-items:center;margin:0;padding:10px;list-style-type:none;opacity:1;overflow:hidden; 
   }

  /* svg{margin: 5px 70px;}  */
}


.dev{font-size:50px;padding:10px}
.dev-icon{width:100px;height:100px;filter:brightness(0.9)}
.dev-i{width:140px;height:180px}




@keyframes move-left {
  0%{transform:translateX(0%)}
  100%{transform:translateX(-50%)}
  }
  .animate-move-left{animation:move-left 1s linear infinite}
  @keyframes move-right {
  0%{transform:translateX(-50%)}
  100%{transform:translateX(0%)}
  }
  .animate-move-right{animation:move-right 1s linear infinite}

  .-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }