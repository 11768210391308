/* header{z-index:9999999} */
.setBg{background-color:#000}
.hidden {display: none;}
.bg-accent {--tw-bg-opacity: 1;background-color: rgb(92 255 228/var(--tw-bg-opacity));}
#header{background: #000; z-index: 99999999999999;
    padding-top: var(--space-site-header);
    padding-bottom: var(--space-site-header);
}
@media (max-width: 600px){
    .setBg{background:transparent!important}
}

